import { OptionMap } from './ImageSwitcher'
import * as jsurl from 'jsurl'
import { deepCopy } from './util'

function serializeOpts(opts: OptionMap): string {
	let map = deepCopy(opts)
	Object.keys(map).forEach(key => {
		let opt = map[key]
		delete opt.id
		if (opt.contrast) opt.contrast = +opt.contrast.toFixed(2)
		// if (opt.similarity) opt.similarity = +opt.similarity.toFixed(2)
		// if (opt.refLevel) opt.refLevel = +opt.refLevel.toFixed(2)
	})

	return jsurl.stringify(map)
}

function deserializeOpts(optString: string): OptionMap {
	let obj = jsurl.parse(optString) as OptionMap

	if (!obj || !obj.body) {
		throw new Error('invalid url string')
	}

	Object.keys(obj).forEach(key => {
		let opt = obj[key]

		// validation – doesn't need to be crazy, we can just fall back to defaults
		if (typeof opt == 'object') {
			if (!opt.color) {
				throw new Error('invalid url string: missing color for ' + key)
			}

			opt.id = key
		}
	})

	return obj as OptionMap
}

function parseQuery(q: string) {
	if (!q) return null
	if (q[0] == '?') q = q.slice(1)

	let pairs = q.split('&')
	let obj: any = {}
	pairs.forEach(pair => {
		let i = pair.indexOf('=')
		if (i > -1) {
			let k = pair.slice(0, i)
			let v = pair.slice(i + 1)
			obj[k] = decodeURIComponent(v)
		}
	})

	return obj
}

function getOptsFromUrl(qs: string): OptionMap | null {
	if (qs) {
		let query = parseQuery(qs)
		if (query.opts) {
			console.log('parsing opts from url')
			try {
				let opts = deserializeOpts(query.opts) as OptionMap
				return opts
			}
			catch (e) {
				console.error('invalid opts in url', e)
			}
		}
	}

	return null
}

export { serializeOpts, deserializeOpts, getOptsFromUrl }
