import * as React from 'react'
import { useContext } from 'react'
import { OptionMap } from './ImageSwitcher'

interface AppContext {
	opts: OptionMap
	setOpts: React.Dispatch<React.SetStateAction<OptionMap>>
}

const AppContext = React.createContext(null as AppContext)

const useAppContext = () => useContext(AppContext)

export { AppContext, useAppContext }
