import * as React from 'react'
import { useEffect, useState } from 'react'
import { useAppContext } from './AppContext'
import { getOptsFromUrl } from './urlHelpers'
import { deepCopy } from './util'

declare var require: (path: string) => any

// const backMain: string = require('./images/bronco-red.jpg')
// const backAlt: string = require('./images/bronco-blue.jpg')
const sideAlt: string = require('./images/u725_21_2dr_race_red_env_2.jpg')
const sideMain: string = require('./images/u725_21_2dr_cactus_grey_env_2.jpg')
const snowMain: string = require('./images/u725_21_oxford_white_env_35.jpg')
const snowAlt: string = require('./images/u725_21_race_red_env_35.jpg')
const bdMain: string = require('./images/bd-main.png')
const bdBody: string = require('./images/bd-body.png')
const bdTop: string = require('./images/bd-top.png')
// const bdStripe: string = require('./images/bd-stripe.png')

const gbMain: string = require('./images/gb-main-2.png')
const gbTop: string = require('./images/gb-top-2.png')
const gbBody: string = require('./images/gb-body-2.png')

let renderTimeout = 0

interface OptionValue {
	id: string
	color: string | {ref: string}
	contrast?: number
	similarity?: number
	refLevel?: number
}

interface OptionMap {
	body: OptionValue
	top?: OptionValue
	favorite?: boolean
	legacyFavorite?: boolean
	image: string
}

type AppState = OptionMap // & { image: string }

interface ImageDef {
	id: string
	main: string
	description: string
	watermark: 'left' | 'right'
	options?: OptionDef[]
	disclaimer?: string
}
interface OptionDef {
	id: string
	label: string
	imgSrc: string
	optional?: boolean
	bounds?: [number, number, number, number]
	showContrast?: boolean
	showRefLevel?: boolean
	showSimilarity?: boolean
	similarity: number
	refLevel: number
	contrast?: number
	defaults?: {
		name: string
		color: string | {ref: string}
		contrast?: number
	}[]
}

const images: ImageDef[] = [
	{
		id: 'bd',
		main: bdMain,
		description: 'Bronco 4dr Black Diamond',
		watermark: 'right',
		options: [
			{
				id: 'body',
				label: 'Body Color',
				imgSrc: bdBody,
				// bounds: [0, 0, 500, 500],
				bounds: [438, 322, 1214, 430],
				similarity: 140,
				refLevel: 210,
				showContrast: true,
			},
			{
				id: 'top',
				label: 'Top Color',
				optional: true,
				imgSrc: bdTop,
				bounds: [634, 176, 634+889, 176+221],
				similarity: 0,
				contrast: -.2,
				showContrast: true,
				refLevel: 90,
				defaults: [
					{name: 'white', color: '#cccccc'},
					{name: 'black', color: '#333333'},
					{name: 'carb gray', color: '#666666'},
					{name: 'body color', color: {ref: 'body'}},
				]
			},
			// {
			// 	id: 'stripe',
			// 	label: 'Stripe',
			// 	optional: true,
			// 	imgSrc: bdStripe,
			// 	// bounds: [634, 176, 634+889, 176+221],
			// 	// showRefLevel: true,
			// 	showSimilarity: true,
			// 	similarity: 100,
			// 	refLevel: 210,
			// 	// defaults: [
			// 	// 	{name: 'body color', color: {ref: 'body'}},
			// 	// 	{name: 'white', color: '#cccccc'},
			// 	// ]
			// },
		]
	},
	// {
	// 	main: backMain,
	// 	description: 'Bronco rear view',
	// 	watermark: 'right',
	// 	options: [
	// 		{
	// 			id: 'body',
	// 			label: 'Body Color',
	// 			imgSrc: backAlt,
	// 			// bounds: [0, 0, 500, 500],
	// 			similarity: 30,
	// 			refLevel: 85,
	// 			showContrast: true,
	// 		},
	// 	]
	// },
	{
		id: 'side',
		main: sideMain,
		description: 'Bronco Badlands side view',
		watermark: 'right',
		options: [
			{
				id: 'body',
				label: 'Body Color',
				imgSrc: sideAlt,
				similarity: 140,
				refLevel: 185,
				showContrast: true,
			},
		]
	},
	{
		main: snowMain,
		id: 'snow',
		description: 'Bronco 4dr snow',
		watermark: 'left',
		options: [
			{
				id: 'body',
				label: 'Body Color',
				imgSrc: snowAlt,
				similarity: 140,
				refLevel: 220,
				showContrast: true,
			},
		]
	},
	{
		main: gbMain,
		id: 'snow2',
		description: 'Snow w/ color top',
		watermark: 'left',
		disclaimer: 'Note: This only only looks good/realistic with dark colors, sorry. Try a dark blue.',
		options: [
			{
				id: 'body',
				label: 'Body Color',
				imgSrc: gbBody,
				bounds: [0, 0, 500, 500],
				similarity: 10,
				refLevel: 115,
				showContrast: true,
			},
			{
				id: 'top',
				label: 'Top Color',
				optional: true,
				showContrast: true,
				imgSrc: gbTop,
				bounds: [0, 0, 500, 500],
				similarity: 50,
				refLevel: 199,
				defaults: [
					{name: 'white', color: '#ffffff'},
					{name: 'black', color: '#333333'},
					{name: 'carb gray', color: '#666666'},
					{name: 'body color', color: {ref: 'body'}},
				]
			},
		]
	},
]

interface ImageSwitcherProps {
	canvasRef: React.RefObject<HTMLCanvasElement>
}

function getRandomOpts(imageDef: ImageDef) {
	let opts = getDefaultOpts(imageDef)

	let color = resolveColor(opts, opts.body.color)
	let parsedColor = parseColor(color)
	let l = lightness(parsedColor.r, parsedColor.g, parsedColor.b) / 255

	if (l < .5) {
		opts.body.contrast = 1 - l * 2
	}

	if (opts.top/*  && (opts.top.color as {ref: string})?.ref */) {
		let color = resolveColor(opts, opts.top.color)
		let parsedColor = parseColor(color)
		let l = lightness(parsedColor.r, parsedColor.g, parsedColor.b) / 255

		if (l > .7) {
			opts.top.contrast =  -.2 -(l - .7) * 2
		}
		else {
			opts.top.contrast = -.2
		}
	}

	return opts
}

function getDefaultOpts(imageDef: ImageDef) {
	if (!imageDef.options) return null

	let opts = {
		image: imageDef.id,
	} as OptionMap

	imageDef.options.forEach(option => {
		let defOpt = option.defaults && option.defaults[Math.floor(Math.random() * option.defaults.length)]
		let opt: OptionValue = {
			id: option.id,
			color: defOpt?.color,
			contrast: defOpt?.contrast,
		}
		if (!opt.color && !option.optional) {
			opt.color = randomColor()
		}
		opts[option.id] = opt
	})

	return opts
}

const ImageSwitcher: React.FC<ImageSwitcherProps> = (props) => {
	const [currentImage, setCurrentImage] = useState(images[0])
	const {opts, setOpts} = useAppContext()

	useEffect(() => {
		console.log(opts)
		if (!opts) {
			let newOpts: typeof opts
			let nextImage = currentImage

			try {
				newOpts = getOptsFromUrl(location.search)

				if (newOpts) {
					history.replaceState(null, null, '/')

					if (newOpts.image && currentImage.id != newOpts.image) {
						let image = images.find(img => img.id == newOpts.image)
						if (image) {
							setCurrentImage(image)
							nextImage = image
						}
					}
				}
			}
			catch (e) {
				newOpts = null
			}
			if (!newOpts) {
				newOpts = getRandomOpts(nextImage)
			}

			newOpts.image = nextImage.id

			setOpts(newOpts)
		}
		else if (opts.legacyFavorite) {
			delete opts.legacyFavorite
			let newOpts: OptionMap = {
				...getDefaultOpts(currentImage),
				...opts,
				image: currentImage.id,
			}
			setOpts(newOpts)
		}
		else if (opts.favorite) {
			delete opts.favorite

			let image: ImageDef
			if (opts.image && currentImage.id != opts.image) {
				image = images.find(img => img.id == opts.image)
				if (image) {
					setCurrentImage(image)
				}
				else {
					console.error('did not find image', opts.image)
					debugger
				}
			}

			if (!image) image = currentImage

			setOpts({...getDefaultOpts(image), ...opts, image: image.id})
		}
	}, [opts])

	const updateOption = (optId: string, optValue: OptionValue) => {
		let newOpts = deepCopy(opts)
		newOpts[optId] = {
			id: optId,
			color: optValue.color,
			contrast: optValue.contrast,
		}

		setOpts(newOpts)
	}

	const updateOptionValue = <P extends keyof OptionValue>(optId: string, prop: P, value: OptionValue[P]) => {
		let newOpts = deepCopy(opts)
		newOpts[optId][prop] = value
		setOpts(newOpts)
	}

	useEffect(() => {
		rerender()
	}, [currentImage, opts])


	const rerender = () => {
		if (renderTimeout) clearTimeout(renderTimeout)
		renderTimeout = setTimeout(() => {
			_rerender(currentImage, opts, props.canvasRef.current)
		}, 100)
	}

	const randomize = () => {
		let opts = getRandomOpts(currentImage)
		setOpts(opts)
	}

	return (
		<div>
			<p className="Thumbs">
				Choose your image:

				<ul className="noList">
					{images.map((imgSet, i) => (
						<li key={i}>
							<button onClick={e => {
								let newState = {...getDefaultOpts(imgSet), ...opts} as OptionMap
								newState.image = imgSet.id
								setOpts(newState)
								setCurrentImage(imgSet)

								rerender()
							}}>
								{imgSet.description}
							</button>
						</li>
					))}
				</ul>
			</p>

			<div style={{display: 'none'}}>
				<img crossOrigin="anonymous" className="thumb" src={currentImage.main} id="srcImg" />
				{currentImage.options?.map(opt => (
					<img crossOrigin="anonymous" className="thumb"
						key={opt.id} src={opt.imgSrc} id={'img-' + opt.id} />
				))}
			</div>

			{currentImage.disclaimer && <p>{currentImage.disclaimer}</p>}

			<button onClick={randomize}>Randomize</button>

			<p>
				{opts && currentImage?.options?.map(option => {
					let optId = option.id
					let opt = opts[optId]
					if (!opt) return

					let color = resolveColor(opts, opt.color)
					let optionSelected = false

					return <div key={option.id}>
						{option.optional && <>
							<input type="checkbox" checked={!!opt.color} onChange={e => {
								let color = opt.color ? undefined : getRandomOpts(currentImage)[option.id].color
								updateOptionValue(optId, 'color', color)
							}} />
						</>}
						{option.label}
						{(!option.optional || opt.color) && <>
							{option.defaults?.length > 0 && (
								<>&nbsp; <select onChange={e =>
									updateOption(optId, option.defaults[e.currentTarget.value])
								}>
									{option.defaults.map((o, i) => {
										if (opt.color == o.color) optionSelected = true
										return <option key={i} value={i} selected={opt.color == o.color}>{o.name}</option>
									})}
									{!optionSelected && <option key="custom" selected>custom</option>}
								</select></>
							)}

							{typeof opt.color == 'string' && <>
								&nbsp; <input type="color" value={color} onChange={e => updateOptionValue(optId, 'color', e.currentTarget.value)} />
								&nbsp; <input type="text" value={color} onChange={e => updateOptionValue(optId, 'color', e.currentTarget.value)} />
							</>}
							<br/>

							{option.showContrast && <>
								Contrast: <input type="range" value={opt.contrast ?? option.contrast ?? 0} min="-1" max="1.5" step=".1" onChange={e => {
									updateOptionValue(optId, 'contrast', +e.currentTarget.value)
								}} list="steplist" />
							</>}

							{option.showRefLevel && <>
								Reference level: <input type="range" min="0" max="255"
									value={opt.refLevel}
									onChange={e =>
										updateOptionValue(optId, 'refLevel', +e.currentTarget.value)
								} />
							</>}

							{option.showSimilarity && <>
								Similarity threshold: <input type="number" min="0" max="200" value={opt.similarity}
									onChange={e => (
										updateOptionValue(optId, 'similarity', +e.currentTarget.value)
									)
								} />
							</>}
						</>}
					</div>
				})}
			</p>

			<datalist id="steplist"><option>0</option></datalist>
		</div>
	)
}

function randomColor() {
	return '#' + Math.random().toString(16).slice(2, 8)
}

function resolveColor(currentOpts: OptionMap, v: string | {ref: string}) {
	for (let i = 0; typeof v == 'object'; i++) {
		v = currentOpts[v.ref].color
		if (i > 3) throw new Error('unable to resolve color')
	}

	return v
}

function _rerender(currentImage: ImageDef, currentOpts: OptionMap, canvas: HTMLCanvasElement) {
	const ctx = canvas.getContext('2d')!
	const srcImg = document.getElementById('srcImg') as HTMLImageElement
	const srcCtx = createCanvas(srcImg)

	let bail = false

	currentOpts = deepCopy(currentOpts)

	currentImage.options!.forEach(option => {
		if (bail) return

		let opt = currentOpts[option.id]
		if (!opt) {
			bail = true
			return
		}

		let optColor = resolveColor(currentOpts, opt.color)

		if (!optColor) {
			if (!option.optional) {
				throw new Error('missing color')
			}
		}
		else if (optColor.match(/^#?[A-Fa-f0-9]{6}$/)) {
			if (optColor.length == 6) {
				optColor = '#' + optColor
			}
			opt.color = optColor
			// console.log('optColor', optColor)
		}
		else {
			console.log('invalid color', optColor)
			bail = true
		}
	})

	// a color wasn't valid, so we bailed.
	// probably because they're typing in a color and it's only half-typed.
	if (bail) return

	let retry = false, notReady = false, notMatched = false, zeros1 = false, zeros2 = false


	const w = srcCtx.canvas.width
	const h = srcCtx.canvas.height

	notReady = !w || !h
	if (notReady) {
		retry = true
	}

	ctx.canvas.width = w
	ctx.canvas.height = h

	ctx.drawImage(srcImg, 0, 0)

	let pixels: ImageData
	try {
		pixels = ctx.getImageData(0, 0, w, h)
	}
	catch (e) {
		console.error(e)
		retry = true
	}

	currentImage.options!.forEach(option => {
		if (bail || retry) return

		let opt = currentOpts[option.id]
		if (option.optional && !opt.color) return

		const optColor = parseColor(opt.color as string)
		const contrast = opt.contrast || option.contrast || 0
		const refLevel = opt.refLevel || option.refLevel
		const deltaThreshold = option.similarity

		const optCtx = createCanvas(document.getElementById('img-' + option.id) as HTMLImageElement)

		notReady = !w || !h  || !optCtx.canvas.width || !optCtx.canvas.height
		notMatched = w != optCtx.canvas.width || h != optCtx.canvas.height
		if (notReady || notMatched) {
			retry = true
			return
		}

		let pixels1: ImageData = srcCtx.getImageData(0, 0, w, h)
		let pixels2: ImageData = optCtx.getImageData(0, 0, w, h)

		try {
			pixels1 = srcCtx.getImageData(0, 0, w, h)
			pixels2 = optCtx.getImageData(0, 0, w, h)
		}
		catch (e) {
			console.log(e)
			retry = true
			return
		}

		zeros1 = true
		zeros2 = true

		let skipped = 0, drawn = 0

		for (var i = 0; i < w * h * 4; i += 4) {
			let r1 = pixels1.data[i]
			let g1 = pixels1.data[i + 1]
			let b1 = pixels1.data[i + 2]

			let r2 = pixels2.data[i]
			let g2 = pixels2.data[i + 1]
			let b2 = pixels2.data[i + 2]

			let delta = Math.hypot(r1 - r2, g1 - g2, b1 - b2)

			// detect all black - images haven't loaded yet
			if (zeros1 && r1 + g1 + b1 > 0) zeros1 = false
			if (zeros2 && r2 + g2 + b2 > 0) zeros2 = false

			if (delta < 3) {
				skipped++
			}
			else {
				drawn++
				let l = lightness(r1, g1, b1)

				if (contrast !== 0) {
					let dl = l - refLevel
					l += (dl * contrast)
				}

				let newR = (optColor.r * l / refLevel)
				let newG = (optColor.g * l / refLevel)
				let newB = (optColor.b * l / refLevel)

				let blend = delta / deltaThreshold

				if (blend < 1) {
					pixels.data[i] = r1 * (1 - blend) + newR  * blend
					pixels.data[i + 1] = g1 * (1 - blend) + newG  * blend
					pixels.data[i + 2] = b1 * (1 - blend) + newB  * blend
				}
				else {
					pixels.data[i] = newR
					pixels.data[i + 1] = newG
					pixels.data[i + 2] = newB
				}
			}
		}

		// console.log(`${option.label}: skipped ${skipped} pixels, drew ${drawn} pixels`)
	})

	if (zeros1 || zeros2) {
		console.log(zeros1 && 'image 1', zeros2 && 'image2', 'not ready')
		retry = true
	}

	if (notReady || notMatched) {
		console.log(notReady && 'not ready', notMatched && 'notMatched')
		retry = true
	}

	if (retry) {
		setTimeout(() =>
			_rerender(currentImage, currentOpts, canvas)
		, 50)
		return
	}

	ctx.putImageData(pixels, 0, 0)
	ctx.fillStyle = 'white'
	ctx.textBaseline = 'bottom'
	ctx.font = (Math.min(w, h) * .04 | 0) + 'px sans-serif'
	const offs = w * .02
	if (currentImage.watermark === 'left') {
		ctx.textAlign = 'left'
		ctx.fillText('colorizer.mortensoncreative.com', offs, h - offs)
	}
	else {
		ctx.textAlign = 'right'
		ctx.fillText('colorizer.mortensoncreative.com', w - offs, h - offs)
	}
}

function lightness(r: number, g: number, b: number) {
	return (0.2126 * r + 0.7152 * g + 0.0722 * g)
}

function parseColor(color: string) {
	return {
		r: parseInt(color.slice(1, 3), 16),
		g: parseInt(color.slice(3, 5), 16),
		b: parseInt(color.slice(5, 7), 16),
	}
}

function createCanvas(img: HTMLImageElement) {
	const canvas: HTMLCanvasElement = document.createElement('canvas')
	canvas.width = img.naturalWidth
	canvas.height = img.naturalHeight
	const context = canvas.getContext('2d')!
	context.drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight)

	return context
}

export { ImageSwitcher, resolveColor }
export type { ImageSwitcherProps, OptionMap, OptionValue, AppState }
