import * as React from 'react'
import { useRef, useState } from 'react'
import { AppContext, useAppContext } from './AppContext'
import { ImageSwitcher, OptionMap, resolveColor } from './ImageSwitcher'
import { serializeOpts } from './urlHelpers'

interface AppProps {}

const App: React.FC<AppProps> = (props) => {
	const canvasRef = useRef<HTMLCanvasElement>()
	const [opts, setOpts] = useState(null as OptionMap)

	return (
		<AppContext.Provider value={{opts, setOpts}}>
			<div className="App">
				<section id="left">
					<h1>Bronco Colorizer</h1>
					<h3>Instructions</h3>

					<p>Choose the example photo you want to use.</p>
					<p>Use the color picker to change to color of the colorized portions of your photo.</p>
					<p>
						Darker colors sometimes need a little extra contrast to look realistic.
						Usually you'll never want to decrease contrast, but you can try it.
					</p>

					<ImageSwitcher canvasRef={canvasRef} />

					<Favorites />
				</section>

				<section id="right">
					<canvas ref={canvasRef}></canvas>
				</section>

			</div>
		</AppContext.Provider>
	)
}


interface FavoritesProps {}

const Favorites: React.FC<FavoritesProps> = () => {
	const { opts, setOpts } = useAppContext()
	const [favorites, setFavorites] = useState(getFavorites)
	const [nameDraft, setNameDraft] = useState('')

	const addFavorite = (e: React.FormEvent) => {
		e.preventDefault()

		let newFavorites = [{opts: opts, name: nameDraft}, ...favorites]
		setFavorites(newFavorites)
		storeFavorites(newFavorites)
		setNameDraft('')
	}

	const removeFavorite = (notSoFavorite: Favorite) => {
		let i = favorites.indexOf(notSoFavorite)
		let newFavorites = favorites.slice()
		newFavorites.splice(i, 1)
		setFavorites(newFavorites)
		storeFavorites(newFavorites)
	}

	const applyFavorite = (fav: Favorite) => {
		if (fav.opts) {
			setOpts({
				...fav.opts,
				// super hacky.
				favorite: true
			})
		}
		else {
			let opts: OptionMap = {
				body: {
					id: 'body',
					color: fav.color
				},
				legacyFavorite: true,
			} as OptionMap
			setOpts(opts)
		}
	}

	return <div>
		<h3>Favorites</h3>
		<form onSubmit={addFavorite}>
			<input type="text" placeholder="Add a name (optional)" value={nameDraft} onChange={e => setNameDraft(e.target.value)}/>
			&nbsp; <button type="submit">Save Favorite</button>
			&nbsp; <button type="button" className="link" onClick={() => showLink(opts)}>Get Link</button>
		</form>
		<div className="favList">
			{favorites.map((fav, i) => {
				let color = fav.color || fav.opts.body.color as string
				let topColor = fav.opts?.top?.color
				if (topColor) topColor = resolveColor(fav.opts, topColor)
				let name = fav.name || color
				return <div className="item" key={color + fav.name + i}>
					{name} <span style={{background: color}}>&nbsp; &nbsp; &nbsp;</span>
					{topColor && <span style={{background: topColor as string}}>&nbsp; &nbsp; &nbsp;</span>}

					<span style={{float: 'right'}}>
						<button className="link" onClick={() => applyFavorite(fav)}>Apply</button>
						&nbsp; <button className="link" onClick={() => removeFavorite(fav)}>Remove</button>
					</span>
				</div>
			})}
		</div>
	</div>

}

interface Favorite {
	name: string
	color?: string
	opts?: OptionMap
}

function storeFavorites(favs: Favorite[]) {
	localStorage.setItem('favorites', JSON.stringify(favs))
}

function getFavorites() {
	let favorites: Favorite[]

	try {
		favorites = JSON.parse(localStorage.getItem('favorites')) as Favorite[]
	}
	catch(e) {}

	if (!favorites) {
		favorites = []
	}

	return favorites
}

const showLink = (opts: OptionMap) => {
	let q = serializeOpts(opts)
	let link = `${location.origin}${location.pathname}?opts=${q}`

	// console.log so we don't accidentally remove this as unused
	console.log(prompt('Press Ctrl + C to copy this link:', link))
}

export { App }
